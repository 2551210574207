import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom } from '../../../../recoil/atoms/Clients';
import PickListService from '../../../../services/PickListService';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import { Input } from '../../../shared/form-control/Input';
import ActionEditorBase from '../ActionEditorBase';
import { createPickListSource } from '../PickListSource';
import ShowInResourceView from '../ShowInResourceView';

const PickListEditor: FC<EditorProps> = (props) => {
  const { action, patchData } = props;
  const { sourceType, buttonText } = action?.data || {};

  const { t } = useTranslation(['form-builder', 'organisation']);

  const client = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);

  const {
    data: picklistSources = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['picklists', action.id],
    queryFn: () =>
      PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id }).then((res) => {
        return createPickListSource(res.data, t);
      }),
  });

  useEffect(() => {
    if (!buttonText) {
      patchData({ buttonText: t('form-builder:action-properties.picklist.resource.default-button') });
    }
  }, [buttonText, patchData, t]);

  const onSourceTypeChanged = (option: Item) => {
    patchData({ sourceType: option.id });
  };

  const picklistLinkProvider = useCallback(
    (item: Item) => {
      return item.value ? `/clients/${item.value}/picklists/${item.id}` : `/builder/${currentTenantId}/picklists/${item.id}`;
    },
    [currentTenantId],
  );

  const picklistSourceText = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType)?.text;
  const picklistSourceValue = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType)?.value;
  return (
    <div data-cy="picklist-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <Input
        data-cy="button-text"
        placeholder={t('form-builder:action-properties.picklist.resource.button-text')}
        label={t('form-builder:action-properties.picklist.resource.button-text')}
        value={buttonText}
        onChange={(e) => patchData({ buttonText: e.target.value })}
      />

      <DropdownSelect
        data-cy="source"
        className={`${!sourceType && 'mt-8'}`}
        placeholder={t('form-builder:action-properties.picklist.resource.picklist-source')}
        label={t('form-builder:action-properties.picklist.resource.picklist-source')}
        value={{ id: sourceType, text: picklistSourceText || '', value: picklistSourceValue || '' }}
        options={picklistSources}
        onChange={onSourceTypeChanged}
        customListRenderer={OpenInNewTabValueRendererWrapper(picklistLinkProvider)}
        isFetching={isFetching}
        onOpen={refetch}
      />

      <ShowInResourceView {...props} />
    </div>
  );
};

export default PickListEditor;
